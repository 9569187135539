import React, { useState } from "react";
import axios from 'axios';
import Layout from '../components/layout';

const Contact = () => {

	const [serverState, setServerState] = useState({
		submitting: false,
		status: null
	  });
	  const handleServerResponse = (ok, msg, form) => {
		setServerState({
		  submitting: false,
		  status: { ok, msg }
		});
		if (ok) {
		  form.reset();
		}
	  };
	  const handleOnSubmit = e => {
		e.preventDefault();
		const form = e.target;
		setServerState({ submitting: true });
		axios({
		  method: "post",
		  url: "https://getform.io/f/fa2d7103-1995-4ac8-82b6-17bf5985f24c",
		  data: new FormData(form)
		})
		  .then(r => {
			handleServerResponse(true, "Votre message a bien été envoyé", form);
		  })
		  .catch(r => {
			handleServerResponse(false, r.response.data.error, form);
		  });
	  };

	return(
	<Layout>
		<h1>Contact me</h1>
		<form onSubmit={handleOnSubmit}>
			<label>
				<span>Name</span>
				<input type="text" name="name" id="name" required="required"/>
			</label>
			<label>
				<span>Email</span>
				<input type="email" name="email" id="email" required="required"/>
			</label>
			<label>
				<span>Subject</span>
				<input type="text" name="subject" id="subject" required="required" />
			</label>
			<label>
				<span>Message</span>
				<textarea name="message" id="message" rows="5" required="required" />
			</label>
			<button type="submit" disabled={serverState.submitting}>Envoyer</button>
			{serverState.status && (
                <p className={!serverState.status.ok ? "errorMsg" : ""}>
                {serverState.status.msg}
                </p>
            )}
		</form>
	</Layout>
	);
}

export default Contact;